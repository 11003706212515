function generateSacuUcr(
    year,
    countryCode,
    entityCode,
    entityType,
    uniqueReference,
    usageType,
) {
    // Validate the input parameters
    if (!(Number.isInteger(year) && year >= 0 && year <= 9)) {
        throw new Error("Year must be the last digit of the calendar year (0-9).");
    }
    if (!(typeof countryCode === "string" && countryCode.length === 2)) {
        throw new Error("Country code must be a 2-character string.");
    }
    if (
        !(
            typeof entityCode === "string" &&
            entityCode.length >= 1 &&
            entityCode.length <= 13
        )
    ) {
        throw new Error("Entity code must be between 1 and 13 characters.");
    }
    if (!["C", "T", "P"].includes(entityType)) {
        throw new Error("Entity type must be 'C', 'T', or 'P'.");
    }
    if (!(typeof uniqueReference === "string" && uniqueReference.length > 1)) {
        throw new Error("Unique reference must be a non-empty string.");
    }
    if (!["S", "M"].includes(usageType)) {
        throw new Error("Usage type must be 'S' or 'M'.");
    }

    // Pad entity code with leading zeros if it's less than 8 characters
    while (entityCode.length < 8) {
        entityCode = "0" + entityCode;
    }

    // Process the uniqueReference to remove special characters and rearrange it
    if (uniqueReference.includes("/")) {
        const parts = uniqueReference.split("/");
        uniqueReference = parts[1] + parts[0];
    }
    uniqueReference = uniqueReference.replace("/", "");

    // Ensure unique reference length does not exceed 18 characters after processing
    if (uniqueReference.length > 18) {
        throw new Error(
            "Unique reference cannot exceed 18 characters after processing."
        );
    }

    // Construct the SACU UCR
    const source = "CUS";
    const ucr = `${year}${countryCode}${entityCode}${entityType}${source}${uniqueReference}${usageType}`;

    // Ensure the UCR length is between 17 and 35 characters
    if (ucr.length < 17 || ucr.length > 35) {
        throw new Error(
            "The generated SACU UCR must be between 17 and 35 characters long."
        );
    }

    return ucr;
}

// Example usage:
// try {
//     // const year = 4; // Last digit of the calendar year
//     // const countryCode = "ZA"; // UNLOCODE country code   BW SZ NA LS ZA
//     // const entityCode = "25053549"; // Example entity code use 8 one;s if no code
//     // const entityType = "C"; // 'C' for customs client number
//     // const uniqueReference = "LL126538/01"; // Example unique reference
//     // const usageType = "S"; // Single use

//     const sacuUcr = generateSacuUcr(
//         year,
//         countryCode,
//         entityCode,
//         entityType,
//         uniqueReference,
//         usageType
//     );
//     console.log(`Generated SACU UCR: ${sacuUcr}`);
// } catch (e:any) {
//     console.error(`Error: ${e.message}`);
// }

module.exports = generateSacuUcr;